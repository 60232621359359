@import '../theme-bootstrap';
@import '../../../../../all/themes/elc_base/scss/standalone/shared/animations';

$spp_container_max_width: 1105px;

// Skeleton Product Full
.sd-full-skeleton {
  .skeleton-full.elc-product-fullsized {
    display: inline-block;
    width: 100%;
    max-width: $spp_container_max_width;
    margin: 0 auto;
    padding: 96px 0 0;
  }
  .elc-skeleton-region {
    .elc-skeleton-feature {
      &--desktopWrapper {
        display: none;
        @include breakpoint($medium-up) {
          width: 100%;
          display: block;
        }
      }
      &--mobileWrapper {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .elc-skeleton-child {
        &--displayName h1 {
          margin: 12px 0 16px;
          color: $color-black;
          font-family: $header-font-bold;
          font-size: 24px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: get-line-height(24px, 20px);
          text-align: center;
        }
        &--subDisplayName {
          @include shimmer(1.5s, ease-in-out);
          height: 24px;
          width: 50%;
          margin: 0 auto 20px;
        }
        &--ratingAndReviews {
          height: 50px;
          width: 100%;
          margin: 0 0 30px;
          color: $color-black;
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          text-decoration: none;
        }
        &--carousel {
          margin: 0 auto;
          @include breakpoint($medium-up) {
            max-width: 514px;
          }
        }
        &--perfectFor {
          @include shimmer(1.5s, ease-in-out);
          width: 50%;
          margin: 15px 25% 2px;
          height: 19px;
          @include breakpoint($medium-up) {
            margin: 15px 0 2px;
          }
        }
        &--shortDescription {
          width: calc(100% - 16%);
          margin: 0 8% 20px;
          text-align: center;
          @include breakpoint($medium-up) {
            width: 100%;
            margin: 0;
            text-align: $ldirection;
          }
        }
        &--overview {
          @include breakpoint($medium-up) {
            @include shimmer(1.5s, ease-in-out);
            height: 60px;
            width: 100%;
            margin-bottom: 30px;
          }
        }
        &--price {
          @include shimmer(1.5s, ease-in-out);
          height: 30px;
          margin: 0 8%;
          width: calc(100% - 16%);
          @include breakpoint($medium-up) {
            width: 100%;
            margin: 0;
          }
        }
        &--sizePicker {
          @include shimmer(1.5s, ease-in-out);
          height: 47px;
          width: calc(100% - 16%);
          margin: 10px 8% 0;
          @include breakpoint($medium-up) {
            width: 75%;
            margin: 10px 0 0;
          }
        }
        &--installmentsPrice {
          @include shimmer(1.5s, ease-in-out);
          height: 41px;
          margin: 20px 8% 0;
          width: calc(100% - 16%);
          @include breakpoint($medium-up) {
            width: 100%;
            margin: 0;
          }
        }
        &--addToBag {
          width: calc(100% - 16%);
          max-width: 320px;
          height: 48px;
          margin: 30px 8% 20px;
          border-radius: unset;
          font-size: 14px;
          line-height: get-line-height(14px, 20px);
          letter-spacing: 0;
          color: $color-white;
          background: $cr19-dark-pink;
          @include breakpoint($medium-up) {
            width: 100%;
            margin: 30px auto 20px;
            text-align: center;
          }
          @include breakpoint($landscape-up) {
            max-width: none;
          }
        }
        &--promoBanner {
          @include shimmer(1.5s, ease-in-out);
          height: 116px;
          width: 100%;
        }
        &--infoPopUp,
        &--ingredients {
          width: 100%;
          @include shimmer(1.5s, ease-in-out);
          height: 100px;
          margin-top: 40px;
        }
      }
    }
    &--left-column {
      float: $ldirection;
      width: 100%;
      position: relative;
      @include breakpoint($medium-up) {
        width: 55%;
        padding-#{$ldirection}: 80px;
        &::before {
          @include shimmer(1.5s, ease-in-out);
          content: '';
          position: absolute;
          #{$ldirection}: 0;
          height: 65px;
          width: 65px;
        }
      }
      @include breakpoint($landscape-up) {
        padding-#{$ldirection}: 124px;
        &::before {
          content: '';
          height: 109px;
          width: 109px;
        }
      }
      @include breakpoint($xxlarge-up) {
        width: 640px;
      }
    }
    &--right-column {
      float: $ldirection;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 45%;
        height: auto;
        padding-#{$ldirection}: 30px;
      }
      @include breakpoint($xxlarge-up) {
        width: 435px;
        padding: 0;
        margin-#{$ldirection}: 20px;
      }
    }
    &--info-content {
      width: 100%;
    }
  }
}
